import axios from "axios";
import store from "../store";

export default function setup() {
  axios.interceptors.request.use(
    function (config) {
      const token = store.getters.token;

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function (err) {
      return Promise.reject(err);
    }
  );
}

axios.interceptors.response.use(
  function (response) {
    if (response.status === 403) {
      alert("Anda tidak mempunyai akses!");
    }
    return response;
  },
  function (error) {
    if (error.response) {
      //store.dispatch("destroyAuthentication");
      return Promise.reject(error.message);
    }
    return Promise.reject(error.message);
  }
);
