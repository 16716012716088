const state = {
    queryParams: null
};

const getters = {
    queryParams: (state) => state.queryParams,
};


const mutations = {
    setQueryParams(state, data) {
        state.queryParams = data;
    },
};
const actions = {
    async createQueryParams({ commit }, data) {
        commit("setQueryParams", data);
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
