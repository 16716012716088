import axiosClient from "../../config/api"

const state = {
    isLoading: false,
    countFpp: null
}
const getters = {
    countFpp(state) {
        return state.isLoading
    }
}
const mutations = {
    LOADING: (state, loadingStatus) => {
        state.isLoading = loadingStatus
    },
    GET_COUNT_MEMO: (state, response) => {
        state.countFpp = response;
    }
}
const actions = {
    async getCountMemo(context, data) {
        let ep = '/internal-memo/memo/dashboard-kc';
        if (data.role === 'Maintenance') {
            ep = '/internal-memo/memo/dashboard-mt'
        } else {
            ep = '/internal-memo/memo/dashboard-kc'
        }
        try {
            context.commit('LOADING', true)
            const response = await axiosClient.get(ep)
            if (response.status === 200) {
                context.commit("GET_COUNT_MEMO", response.data);
                return response.data;
            } else {
                return false;
            }
        } catch (error) {
            console.error(error)
        } finally {
            context.commit('LOADING', false)
        }
    }
}


export default {
    state,
    getters,
    mutations,
    actions
}