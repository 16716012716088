import axiosClient from "../../config/api";

const state = {
    pengajuanLists: []
};

const getters = {
    pengajuanLists: (state) => state.pengajuanLists,
};
const mutations = {
    setPengajuan(state, response) {
        state.pengajuanLists = response;
    },
};

const actions = {
    async getPengajuan(context, params) {
        try {
            const response = await axiosClient({
                method: 'GET',
                url: 'internal-memo/memo/paginateKuKc',
                params: params
            })

            const datas = response.data;
            const responseData = datas.data.data.map(data => {
                const subJenis = data.kategori_sub == null ? "" : data.kategori_sub.name;
                const jenis = data.kategori_jenis ? data.kategori_jenis.name : ""
                const jenisPengajuan = `${jenis} - ${subJenis}`
                let vendorType = "-"
                if (data.vendor_type === 1) {
                    vendorType = "Vendor"
                }
                if (data.vendor_type === 0) {
                    vendorType = "Internal"
                }
                return {
                    id: data.id,
                    cabang: data.cabang.name,
                    kabkot: data.cabang ? data.cabang.kabupaten_kota.name : "-",
                    kepala_cabang: data.kepala_cabang ? data.kepala_cabang.name : "-",
                    kepala_cabang_senior: data.kepala_cabang_senior ? data.kepala_cabang_senior.name : "-",
                    jenis_pengajuan: jenisPengajuan,
                    catatan: data.catatan,
                    tanggal: data.created_at,
                    status: data.flag,
                    no_pengajuan: data.im_number,
                    memo_rating: data.memo_rating,
                    count_attendance_maintenance: data.memo_maintenance_count_count,
                    total_maintenance: data.total_user_maintenance_count,
                    vendor_type: vendorType
                }
            })

            const pagination = {
                perPage: datas.data.per_page,
                currentPage: datas.data.current_page,
                totalRow: datas.data.total,
            }
            context.commit("setPengajuan", { responseData, pagination });
        } catch (error) {
            console.log(error)
        }
    }
};



export default {
    state,
    getters,
    actions,
    mutations,
};
