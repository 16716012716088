import axiosClient from "../../config/api";

const listKatAkses = (data) => {
  switch (data) {
    case 1:
      return "Approver Kc"
    case 2:
      return "Approver Pic Ga"
    case 3:
      return "Approver Gm Ga"
    case 4:
      return "Approver Gm Support"
    case 5:
      return "Approver Wakil Direktur"
    case 6:
      return "Approver Finance"
    default:
      break;
  }
}


const state = {
  akseslists: [],
  kategoriakseslist: []
};

const getters = {
  akseslists: (state) => state.akseslists,
  kategoriakseslist: (state) => state.kategoriakseslist,
};
const mutations = {
  setAksesList(state, data) {
    state.akseslists = data;
  },
  setListKatAkses(state, data) {
    state.kategoriakseslist = data;
  }
};
const actions = {
  getListKatAkses({ commit }) {
    const data = [
      {
        id: 1,
        name: "Approver Kc"
      },
      {
        id: 2,
        name: "Approver Pic Ga"
      },
      {
        id: 3,
        name: "Approver Gm Ga"
      },
      {
        id: 4,
        name: "Approver Gm Support"
      },
      {
        id: 5,
        name: "Approver Wakil Direktur"
      },
      {
        id: 6,
        name: "Approver Finance"
      },
    ]
    commit("setListKatAkses", data);
    return data
  },
  async getAksesList({ commit }) {
    const response = await axiosClient({
      url: "transaksi/kategori-inventaris/all",
      params: {
        order_by: "DESC"
      }
    });
    const datas = response.data.data.map(item => {
      return {
        kategori_proses: item.kategori_proses,
        kategori_proses_name: listKatAkses(item.kategori_proses),
        user: {
          id: item.user.id,
          name: item.user.name,
          email: item.user.email,
          role_id: item.user.role_id,
          username: item.user.username,
        }
      }
    })
    commit("setAksesList", datas);
    return response
  }
};


export default {
  state,
  getters,
  actions,
  mutations,
};
