import "core-js/es6/promise";
import "core-js/es6/string";
import "core-js/es7/array";
import "@/assets/css/multiselect-bs4.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "./registerServiceWorker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import App from "./App";
import Vue from "vue";
import axios from "axios";
import helper from "./helper";
import router from "./router";
import store from "./store";
import firebase from "firebase";
import InterceptorsSetup from "./helpers/interceptors";
import BootstrapVue from "bootstrap-vue";
import Multiselect from "vue-multiselect";
import VueSweetalert2 from "vue-sweetalert2";
import Vuelidate from "vuelidate";


import Skeleton from 'vue-loading-skeleton';
import "vue-loading-skeleton/dist/vue-loading-skeleton.css"

import VueExpandableImage from "/node_modules/vue-expandable-image/dist/vue-expandable-image";
import StarRating from "/node_modules/vue-star-rating/dist/VueStarRating.common";
import datePicker from "/node_modules/vue-bootstrap-datetimepicker";
import browserDetect from "vue-browser-detect-plugin";
const MODE = process.env.VUE_APP_MODE;
const BASE_PATH =
  MODE === "production"
    ? "https://api.pusatgadai.id"
    : "https://api-dev.pusatgadai.id";

const BASE_FILE_PATH =
  MODE === "production"
    ? "https://api.pusatgadai.id"
    : "https://api-dev.pusatgadai.id";

const COMPANY_NAME = "Pusat Gadai Indonesia";
const COMPANY_ID = "PGI";
const LOGO_PATH = "/img/brand/pgi_logo.png";
const LOGO_COLLAPSE_SIDEBAR = "/img/brand/small.png";
const BACKGROUND_LOGO_PATH = "/img/brand/pgi_bg.jpg";
const DEFAULT_USER = "/img/brand/user.png";
const DEFAULT_KTP = "/img/brand/default-ktp.png";
const TOKEN = localStorage.getItem("token");

axios.defaults.baseURL = BASE_PATH;
axios.defaults.timeout = 120000;
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}` || null;

Vue.use(BootstrapVue);
Vue.use(InterceptorsSetup);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueExpandableImage);
Vue.component("multiselect", Multiselect);
Vue.component("star-rating", StarRating);
Vue.use(require("vue-moment"));
Vue.use(datePicker);
Vue.use(browserDetect);
Vue.use(Skeleton)
const requireComponent = require.context(
  "./globals",
  false,
  /[A-Z]\w+\.(vue|js)$/
);
const requireComponentGlobal = require.context(
  "./globals", // Path to the components folder
  false, // Whether to look in subdirectories
  /Base[A-Z]\w+\.(vue|js)$/ // Regular expression to match component filenames
);

requireComponentGlobal.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = fileName
    .split("/")
    .pop()
    .replace(/\.\w+$/, "");

  Vue.component(componentName, componentConfig.default || componentConfig);
});

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  let compName = fileName.includes("Loading")
    ? "LoadingWidget"
    : "ModalConfirm";

  Vue.component(compName, componentConfig.default || componentConfig);
});

// Interceptors for 401 responses (unauthorized)
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status == 401) {
      let fcmToken = localStorage.getItem("fcmToken");

      if (fcmToken != null) {
        // Request logout
        let payload = new FormData();
        payload.append("token", fcmToken);
        axios.post("/user/logout", payload);
        firebase.messaging().deleteToken(fcmToken);
      }

      axios.defaults.headers.common["Authorization"] = "";
      var version = localStorage.getItem("version");
      localStorage.clear();
      localStorage.setItem("version", version);

      router.push("/login");
    }

    return Promise.reject(error);
  }
);
/// ===

// Interceptors for 403 responses (unautenticated)
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status == 403) {
      let fcmToken = localStorage.getItem("fcmToken");

      if (fcmToken != null) {
        // Request logout
        let payload = new FormData();
        payload.append("token", fcmToken);
        axios.post("/user/logout", payload);
        firebase.messaging().deleteToken(fcmToken);
      }

      axios.defaults.headers.common["Authorization"] = "";
      var version = localStorage.getItem("version");
      localStorage.clear();
      localStorage.setItem("version", version);
      router.push("/login");
    }

    return Promise.reject(error);
  }
);
/// ===

axios.defaults.baseURL = BASE_PATH;
axios.defaults.timeout = 120000;
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.common["Authorization"] =
  localStorage.getItem("token") || "";

// todo
// cssVars()

/// ==== SETUP FIREBASE
if (firebase.messaging.isSupported()) {
  //https://medium.com/@n11sh1/how-to-build-pwa-w-vue-cli-3-service-workers-add-to-home-screen-push-notifications-b519c49e142d
  const firebaseConfig = {
    apiKey: "AIzaSyCeCCY0eWnLguB3rGR_rT6Vl5Oh1Kp3ja8",
    authDomain: "pusat-gadai-indonesia-ab0d9.firebaseapp.com",
    databaseURL: "https://pusat-gadai-indonesia-ab0d9.firebaseio.com",
    projectId: "pusat-gadai-indonesia-ab0d9",
    storageBucket: "pusat-gadai-indonesia-ab0d9.appspot.com",
    messagingSenderId: "449052199748",
    appId: "1:449052199748:web:65069d087f3537004a2511",
    measurementId: "G-PEBD1EGZMY",
  };

  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
  }

  const messaging = firebase.messaging();
  messaging.usePublicVapidKey(
    "BNb_DBKszca9ueXR2-YVGU0O5xZhkUvsfSnrfjovDnQpnEJCFpxq6W7_bycuQc0zFPpxY8wyiLoDhI0RT4Ta4kQ"
  );

  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      messaging.useServiceWorker(registration);
    })
    .catch((err) => {
      console.log(err);
    });
}

Vue.use(BootstrapVue);
Vue.component("multiselect", Multiselect);

Vue.prototype.$firebase = firebase;
Vue.prototype.$axios = axios;
Vue.prototype.$store = store;
Vue.prototype.$helper = helper;
Vue.prototype.$basePath = BASE_PATH;
Vue.prototype.$baseFilePath = BASE_FILE_PATH;
Vue.prototype.$footerName = COMPANY_NAME;
Vue.prototype.$companyId = COMPANY_ID;
Vue.prototype.$logoPath = LOGO_PATH;
Vue.prototype.$logoCollapseSidebar = LOGO_COLLAPSE_SIDEBAR;
Vue.prototype.$bgLoginPath = BACKGROUND_LOGO_PATH;
Vue.prototype.$defaultUser = DEFAULT_USER;
Vue.prototype.$defaultKTP = DEFAULT_KTP;

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  let compName = fileName.includes("Loading")
    ? "LoadingWidget"
    : "ModalConfirm";

  Vue.component(compName, componentConfig.default || componentConfig);
});

import OtpInput from "@bachdgvn/vue-otp-input";

Vue.component("v-otp-input", OtpInput);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  store,
  router,
  template: "<App/>",
  components: {
    App,
  },
});
