<template>
  <div class="loading-panel text-center mt-5 mb-5">
    <template v-if="status == 0">
      <b-spinner small variant="secondary"></b-spinner>
      <span class="text-secondary"> Harap tunggu...</span>
    </template>

    <template class="mx-auto loading-failed" v-if="status == -1">
      Gagal terhubung ke server.<br />
      <b-btn class="mt-2" variant="primary" @click="reloadPage"
        >Coba lagi</b-btn
      >
    </template>
  </div>
</template>

<script>
export default {
  name: "loading-widget",
  props: {
    status: { type: Number, required: true },
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>
