<template>
  <b-table
    bordered
    hover
    show-empty
    :busy="busy"
    :items="items"
    :fields="fields"
    responsive
  >
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope"
    >
      <slot :name="slot" v-bind="scope"></slot>
    </template>
  </b-table>
</template>
<script>
export default {
  name: "base-table",
  props: {
    busy: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    fields: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
</script>
