<template>
  <b-modal
    :id="id"
    :title="title"
    centered
    :no-close-on-backdrop="isSubmitting"
    :no-close-on-esc="isSubmitting"
    :hide-header-close="isSubmitting"
    @shown="focusBtnSubmit"
  >
    {{ desc }}
    <p class="mb-0 mt-2" v-if="boldMessage != ''">
      <strong>{{ boldMessage }}</strong>
    </p>
    <b-alert
      class="mt-4 mb-0"
      variant="primary"
      :show="alertMessage != ''"
      v-bind:html="alertMessage"
      >{{ alertMessage }}</b-alert
    >
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        name="cancelSubmitBtn"
        @click="cancel()"
        :disabled="isSubmitting"
      >
        Cancel
      </b-button>
      <b-button
        variant="pgiBtn"
        ref="btnSubmit"
        name="okeSubmitBtn"
        @click="$emit('submit')"
        :disabled="isSubmitting"
      >
        <b-spinner small type="grow" v-if="isSubmitting"></b-spinner>
        {{ isSubmitting ? "Memproses..." : "Ya" }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "modal-confirm",
  props: {
    id: { type: String },
    title: { type: String },
    desc: { type: String },
    isSubmitting: { type: Boolean },
    alertMessage: { type: String, default: "" },
    boldMessage: { type: String, default: "" },
  },
  methods: {
    focusBtnSubmit() {
      this.$refs.btnSubmit.focus();
    },
  },
};
</script>
