import axiosClient from "../../config/api";
import moment from "moment";
import helper from "../../helper";
const state = {
  pembelian_list: {},
  pembelian_all: {},
  history_approval: [],
};

const getters = {
  pembelian_all: (state) => state.pembelian_all,
  pembelian_list: (state) => state.pembelian_list, //DETAIL PEMBELIAN LIST
  history_approval: (state) => state.history_approval,
};


const mutations = {
  setDataPembelian(state, data) {
    state.pembelian_all = data;
  },
  setDetailPembelian(state, data) {
    state.pembelian_list = data;
  },
  setHistoryApproval(state, data) {
    state.history_approval = data;
  },
};
const actions = {
  async getDataPembelian({ commit }, params) {
    const response = await axiosClient({
      url: 'transaksi/pembelian',
      params: params
    })
    const datas = response.data.data
    const fixData = datas.data.map(data => {
      return {
        ...data,
        id: data.id,
        no_invoice: data.no_invoice,
        tanggal: moment(data.tanggal).format("DD-MM-YYYY"),
        supplier: data.supplier ? data.supplier.nama : "-",
        shop_name: data.shop_name,
        totalUnit: data.total_unit,
        totalPembelian: helper.rupiah(data.total_pembelian),
        status: data.flag,
        total_approval: data.total_approval
      }
    })

    const data = {
      data: fixData,
      per_page: datas.per_page,
      size: datas.per_page,
      from: datas.from,
      limit: datas.to,
      current_page: datas.current_page,
      total: datas.total,
    }
    commit("setDataPembelian", data);
    return data;
  },
  async getDetailPembelian({ commit }, id) {
    const response = await axiosClient({
      url: "transaksi/pembelian/" + id,
    });
    const data = response.data.data;
    commit("setDetailPembelian", data);
    return data;
  },
  async getHistoryApproval({ commit }, id) {
    const response = await axiosClient({
      url: `transaksi/pembelian/history-approve-pembelian?id_pembelian=${id}`,
    });
    const data = response.data.data;
    commit("setHistoryApproval", data);
    return data;
  },
  async uploadPembelianSingleCabang({ commit }, data) {
    let form = new FormData();
    form.append(`file`, data.file);
    form.append(`id_pembelian`, data.id_pembelian);
    try {
      const response = await axiosClient({
        method: 'POST',
        url: 'transaksi/pembelian/pembelian-detail-import',
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: form
      });
      return response;
    } catch (error) {
      console.log(error)
    }

  },
  async prosesPembelian({ commit }, data) {
    try {
      const response = await axiosClient({
        method: 'POST',
        url: 'transaksi/pembelian/update',
        data: data
      });
      return response;
    } catch (error) {
      console.log(error)
    }
  },
  async updateStatusPembelianMultiple({ commit }, data) {
    try {
      const response = await axiosClient({
        method: 'POST',
        url: 'transaksi/pembelian/update/detail/all',
        data: data
      });
      return response.status === 200;
    } catch (error) {
      return false
    }
  },
  async updateStatusPembelianSingle({ commit }, data) {
    try {
      const response = await axiosClient({
        method: 'POST',
        url: 'transaksi/pembelian/update/detail',
        data: data
      });
      return response.status === 200;
    } catch (error) {
      return false
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

// transaksi/pembelian
