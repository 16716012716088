import axiosClient from "../../config/api";

const state = {
  category_jenis: [],
  divisilist: [],
  divisi: [],
  cabangLists: [],
  kabupatenLists: [],
  kurirLists: [],
  roles: [],
  supplierList: []
};
const getters = {
  cabangLists: (state) => state.cabangLists,
  kabupatenLists: (state) => state.kabupatenLists,
  kurirLists: (state) => state.kurirLists,
  roles: (state) => state.roles,
  supplierList: (state) => state.supplierList,
  divisilist: (state) => state.divisilist,
};
const mutations = {
  setKabupaten(state, kabupaten) {
    state.kabupatenLists = kabupaten;
  },
  setCabang(state, cabang) {
    state.cabangLists = cabang;
  },
  setKurir(state, kurir) {
    state.kurirLists = kurir;
  },
  setRole(state, roles) {
    state.roles = roles;
  },
  setModal(state, data) {
    state.modalOpen = data;
  },
  setSupplier(state, data) {
    state.supplierList = data;
  },
  setDivisi(state, data) {
    state.divisilist = data;
  },
};
const actions = {
  async getKabupaten({ commit }) {
    const response = await axiosClient({
      url: "admin/wilayah/kabupaten",
    });
    commit("setKabupaten", response.data.data);
  },
  async getKategoryJenis(context, type) {
    try {
      const response = await axiosClient({
        url: "/internal-memo/kategori-sub/all",
      });
      let filteredData;
      if (response.status === 200) {
        filteredData = response.data.data.filter(
          (i) => i.id_kategori_jenis === type
        );
      }
      const dataOk = filteredData.map((item) => {
        return {
          id: item.id,
          id_kategori_fpp: item.id_kategori_fpp,
          id_kategori_jenis: item.id_kategori_jenis,
          name: item.name,
          sla: item.sla,
          value: item.id,
          text: item.name,
        };
      });
      return dataOk;
    } catch (error) {
      console.log(error, "store global");
    }
  },
  async getDivisi({ commit }, option) {
    try {
      const response = await axiosClient({
        url: "/admin/devisi/all",
      });
      if (response.status === 200) {
        const datas = response.data.data;
        let dataOK = []
        if (option === "all") {
          dataOK = datas.map(item => {
            return {
              id: item.DivisiID,
              name: item.nm_Divisi,
              value: item.DivisiID,
              text: item.nm_Divisi,
              id_divisi: item.DivisiID,
              kd_divisi: item.kd_Divisi,
              nm_divisi: item.nm_Divisi,
            }
          })
        } else {
          const dataFilter = datas.filter((item) => {
            if (item.DivisiID === 6 || item.DivisiID === 2) {
              return item;
            }
          });
          dataOK = dataFilter.map((item) => {
            return {
              id: item.DivisiID,
              name: item.nm_Divisi,
              value: item.DivisiID,
              text: item.nm_Divisi,
              id_divisi: item.DivisiID,
              kd_divisi: item.kd_Divisi,
              nm_divisi: item.nm_Divisi,
            };
          });

        }
        commit("setDivisi", dataOK);
        return dataOK;
      }
    } catch (error) {
      console.log(error, "store global");
    }
  },
  async getJenisPengajuan() {
    try {
      const response = await axiosClient({
        url: "/internal-memo/kategori-jenis/all",
      });
      if (response.status === 200) {
        const data = response.data.data;
        const disabledItems = [2, 3];
        const dataOK = data.map((i) => {
          return {
            id: i.id,
            name: i.name,
            value: i.id,
            text: i.name,
            disabled: disabledItems.includes(i.id),
          };
        });
        return dataOK;
      }
    } catch (error) {
      console.log(error, "store global");
    }
  },
  async getCabang({ commit }) {
    const response = await axiosClient({
      url: "/admin/cabang/all",
    });
    const cabang = response.data.data.map(item => {
      return {
        ...item,
        text: item.name
      }
    })
    commit("setCabang", cabang);
  },
  async getKurir({ commit }) {
    const response = await axiosClient({
      url: "/admin/kurir/all",
    });
    commit("setKurir", response.data.data);
  },
  async getRole({ commit }) {
    const response = await axiosClient({
      url: "admin/role",
    });
    commit("setRole", response.data.data);
    return response.data.data
  },
  async getSupplier({ commit }) {
    const response = await axiosClient({
      url: "supplier/all",
    });
    commit("setSupplier", response.data.data);
    return response.data.data
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
