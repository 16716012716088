import axiosClient from "../../config/api";

const state = {
  baranglist: [],
  pemakaianbarang: [],
  pengirimanbarang: [],
};

const getters = {
  baranglist: (state) => state.baranglist,
  pemakaianbarang: (state) => state.pemakaianbarang,
  pengirimanbarang: (state) => state.pengirimanbarang,
};

const mutations = {
  setLaporanBarang(state, data) {
    state.baranglist = data;
  },
  setLaporanPemakaianBarang(state, data) {
    state.pemakaianbarang = data;
  },
  setLaporanPengirimanBarang(state, data) {
    state.pengirimanbarang = data;
  },
};

const actions = {
  async getLaporanBarang({ commit }, params) {
    const response = await axiosClient({
      url: "admin/laporan/stokbarang",
      params: {
        ...params,
        order_by: "DESC"
      }
    });
    commit("setLaporanBarang", response.data.data);
    return response.data.data
  },
  async getLaporanPemakaianBarang({ commit }, params) {
    const response = await axiosClient({
      url: "admin/laporan/pemakaian",
      params: {
        ...params,
        order_by: "DESC"
      }
    });
    commit("setLaporanPemakaianBarang", response.data.data);
    return response.data.data
  },
  async getLaporanPengirimanBarang({ commit }, params) {
    const response = await axiosClient({
      url: "admin/laporan/pengiriman",
      params: {
        ...params,
        order_by: "DESC"
      }
    });
    commit("setLaporanPengirimanBarang", response.data.data);
    return response.data.data
  },
};


export default {
  state,
  getters,
  actions,
  mutations,
};
