import axiosClient from "../../config/api";

const state = {
    refund_pending_list: [],
    refund_pending_count: [],
    refund_history: {}
};

const getters = {
    refund_pending_count: (state) => state.refund_pending_count,
    refund_history: (state) => state.refund_history,
};


const mutations = {
    setCountRefundPending(state, data) {
        state.refund_pending_count = data;
    },
    setHistoryRefund(state, data) {
        state.refund_history = data;
    },
};
const actions = {
    async getHistoryRefund({ commit }) {
        const response = await axiosClient({
            url: "transaksi/refund/history-refund",
            params: {
                order_by: "DESC"
            }
        });
        commit("setHistoryRefund", response.data.data);
        return response.data.data
    },
    async getRefundPending({ commit }) {
        const response = await axiosClient({
            url: "transaksi/refund",
            params: {
                order_by: "DESC"
            }
        });
    },
    async getCountRefundPending({ commit }) {
        const response = await axiosClient({
            url: "transaksi/refund/notification"
        });
        commit("setCountRefundPending", response.data.data);
        return response.data.data
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
