import axiosClient from "../../config/api";

const state = {
  internal_memo: null,
  id_memos: [],
  query_global: null,
};

const getters = {
  internal_memo: (state) => state.internal_memo,
  id_memos: (state) => state.id_memos,
  query_global: (state) => state.query_global,
};
const mutations = {
  setInternalMemo(state, data) {
    state.internal_memo = data;
  },
  setMemoIds(state, data) {
    state.id_memos = data;
  },
  setQueryGlobal(state, data) {
    state.query_global = data;
  },
};

const actions = {
  async getInternalMemo({ commit }, params) {
    const response = await axiosClient({
      url: 'internal-memo/memo/index',
      params: {
        per_page: 10,
        ...params,
      }
    });
    const datas = response.data.data
    commit("setInternalMemo", datas);
    return datas
  },
  async createMemoIds({ commit }, data) {
    console.log(data, "[]")
    commit("setMemoIds", data);
  },
  async createQueryGlobal({ commit }, data) {
    commit("setQueryGlobal", data);
  },
  // APPROVE
  async approveMultipleMemo(_, data) {
    try {
      const response = await axiosClient({
        url: 'internal-memo/memo/acc-memo-all',
        method: 'POST',
        data: {
          id: data
        },
      });
      return response.status === 200
    } catch (error) {
      console.log(error)
    }
  },

  // IGNOE
  async ignoreMultipleMemo(_, data) {
    try {
      const response = await axiosClient({
        url: 'internal-memo/memo/ignore/all',
        method: 'POST',
        data: data,
      });
      return response.status === 200
    } catch (error) {
      console.log(error)
    }
  },

  async handleSingleMemo(_, { path, data }) {
    try {
      const response = await axiosClient({
        url: 'internal-memo/memo/' + path,
        method: 'POST',
        data: data,
      });
      return response.status === 200
    } catch (error) {
      console.log(error)
    }
  }
};


export default {
  state,
  getters,
  actions,
  mutations,
};
