import Vue from "vue";
import Router from "vue-router";
import store from "../store";

// Main
const DefaultContainer = () => import("@/containers/DefaultContainer");
const Dashboard = () => import("@/views/Dashboard");
const Login = () => import("@/containers/login/Login");
const NotFound = () => import("@/containers/404/NotFound");
const Pengajuan = () => import("@/views/fpp/Pengajuan");
const DaftarPengajuan = () => import("@/views/fpp/DaftarPengajuan");
const DaftarPengajuanNew = () => import("@/views/fpp/DaftarPengajuanNew");
const Persetujuan = () => import("@/views/fpp/Persetujuan");
const PersetujuanNew = () => import("@/views/fpp/PersetujuanNew");
const BuatSuratTugas = () => import("@/views/fpp/BuatSuratTugas");
const UbahPengajuan = () => import("@/views/fpp/UbahPengajuan");
const UploadBuktiPengajuan = () => import("@/views/fpp/UploadBuktiPengajuan");


const ArsipPengajuan = () => import("@/views/fpp/ArsipPengajuan");
const ArsipPengajuanNew = () => import("@/views/fpp/ArsipPengajuanNew");


const UbahKataSandiMaintenance = () =>
  import("@/containers/maintenance/UbahKataSandiMaintenance");
const DaftarPengajuanMaintenance = () =>
  import("@/views/fpp/DaftarPengajuanMaintenance");
const ArsipPengajuanMaintenance = () =>
  import("@/views/fpp/ArsipPengajuanMaintenance");

//Master General
const MasterDivisi = () => import("@/views/master/master-divisi/Divisi");
const MasterAksesMenu = () =>
  import("@/views/master/masterinventaris/aksesmenu/MasterAksesMenu");
const MasterPemeliharaanFPP = () =>
  import("@/views/master/master-maintenance/Pemeliharaan");
const ManageUser = () =>
  import("@/views/manage-users/ManageUser");

// Master FPP
const MasterKategoriJenisFPP = () =>
  import("@/views/master/master-fpp/KategoriJenisFPP");
const MasterKategoriFPP = () => import("@/views/master/master-fpp/KategoriFPP");
const MasterSubKategoriFPP = () =>
  import("@/views/master/master-fpp/SubKategoriFPP");
const MasterManajemenAksesFPP = () =>
  import("@/views/master/master-fpp/ManajemenAksesFPP");

// Master Cabang
const MasterCabang = () => import("@/views/master/master-cabang/Cabang");
const MasterManajemenCabang = () =>
  import("@/views/master/master-cabang/ManajemenCabang");

const MasterCabangEdit = () => import("@/views/master/master-cabang/NewFormEditCabang");

// MASTER INVENTARIS
const MasterJenisBarang = () =>
  import("@/views/master/masterinventaris/jenisBarang");
const MasterMerkBarang = () =>
  import("@/views/master/masterinventaris/merkBarang");
const MasterTipeBarang = () =>
  import("@/views/master/masterinventaris/tipeBarang");
const MasterMerchanOnline = () => import("@/views/master/masterinventaris/merchan");
const MasterSuplier = () => import("@/views/master/masterinventaris/suplier");
const MasterTipeSuplier = () => import("@/views/master/masterinventaris/tipe-suplier");
const AksesInventaris = () => import("@/views/master/masterinventaris/aksesInventaris");

//INVENTARIS
const HomeInventory = () =>
  import("@/views/inventory/barangMasuk/homeInventory");
const BarangMasuk = () => import("@/views/inventory/barangMasuk/barangMasuk");
const DetailBarangMasuk = () =>
  import("@/views/inventory/barangMasuk/detailBarangMasuk");
const AsetTetap = () => import("@/views/inventory/stokbarang/asetTetap");
const AsetLancar = () => import("@/views/inventory/stokbarang/asetLancar");
const Pembelian = () =>
  import("@/views/inventory/inputdata/pembelian/pembelian");
const InputPembelian = () =>
  import("@/views/inventory/inputdata/pembelian/inputPembelian");
const DetailPembelian = () =>
  import("@/views/inventory/inputdata/pembelian/detailPembelian");
const Pengiriman = () =>
  import("@/views/inventory/inputdata/pengiriman/pengiriman");
const DetailPengiriman = () =>
  import("@/views/inventory/inputdata/pengiriman/detailPengiriman");
const Pemakaian = () =>
  import("@/views/inventory/inputdata/pemakaian/pemakaian");
const InventarisPerorangan = () =>
  import(
    "@/views/inventory/inputdata/inventarisperorangan/inventarisPerorangan"
  );
const ApprovalVoid = () =>
  import("@/views/inventory/approvalVoid/approvalVoid");
const ApprovalVoidPembelian = () =>
  import("@/views/inventory/approvalVoidPembelian/approvalVoidPembelian");
const DetailApprovalVoidPembelian = () =>
  import("@/views/inventory/approvalVoidPembelian/detailApprovalVoidPembelian");
const PembelianAccounting = () =>
  import("@/views/inventory/accounting/pembelian");
const DetailPembelianAccounting = () =>
  import("@/views/inventory/accounting/detailPembelian");

// Laporan
const LaporanStokBarang = () =>
  import("@/views/inventory/Laporan/StokBarang/index");
const LaporanInventaris = () =>
  import("@/views/inventory/Laporan/Inventaris/index");
const LaporanPemakaian = () =>
  import("@/views/inventory/Laporan/Pemakaian/index");
const LaporanPengiriman = () =>
  import("@/views/inventory/Laporan/Pengiriman/index");
const LaporanPembelian = () =>
  import("@/views/inventory/Laporan/Pembelian/index");
// End Laporan

const HistoryBarang = () =>
  import("@/views/inventory/Laporan/StokBarang/historyBarang");
const TerimaPembelian = () =>
  import("@/views/inventory/terimaPembelian/terimaPembelian");
const DetailTerimaPembelian = () =>
  import("@/views/inventory/terimaPembelian/detailTerimaPembelian");

const SelisihBarang = () =>
  import("@/views/inventory/selisihBarang/listSelisihBarang");
const ManageSelisihBarang = () =>
  import("@/views/inventory/selisihBarang/manageSelisihBarang");
const TerimaSelisihBarang = () =>
  import("@/views/inventory/selisihBarang/terimaSelisihBarang");
const ArsipSelisihBarang = () =>
  import("@/views/inventory/selisihBarang/arsipSelisih");
// const DetailTerimaPembelian = () =>
//   import("@/views/inventory/terimaPembelian/detailTerimaPembelian");


const RefundPending = () => import("@/views/refund/RefundPending");
const RefundPembelianHistory = () => import("@/views/refund/RefundHistory");


// Testing
const Testing = () => import("@/views/testing/Testing");

Vue.use(Router);
const emptyPage = {
  component: {
    render(c) {
      return c("router-view");
    },
  },
};

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/dashboard",
      name: "Home",
      meta: { requiresAuth: true },
      component: DefaultContainer,
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          meta: {
            requiresAuth: true,
            role: "DAS",
          },
          component: Dashboard,
        },
        {
          path: "pengajuan",
          name: "FPP",
          meta: { requiresAuth: true, role: "FPP" },
          component: Pengajuan,
        },
        {
          path: "old-daftar-pengajuan",
          name: "Daftar FPP",
          meta: { requiresAuth: true, role: "DFP" },
          component: DaftarPengajuan,
        },
        {
          path: "daftar-pengajuan",
          name: "Daftar FPP",
          meta: { requiresAuth: true, role: "DFP" },
          component: DaftarPengajuanNew,
        },
        {
          path: "/",
          redirect: "/daftar-pengajuan",
          meta: { requiresAuth: true },
          component: UbahPengajuan,
          children: [
            {
              path: "ubah-pengajuan/:id",
              name: "Ubah Pengajuan",
              meta: {
                requiresAuth: true,
                role: "DFP",
              },
              component: UbahPengajuan,
            },
          ],
        },

        {
          path: "/",
          redirect: "/daftar-pengajuan",
          meta: { requiresAuth: true, role: "DFP" },
          component: UploadBuktiPengajuan,
          children: [
            {
              path: "unggah-bukti-pekerjaan/:id",
              name: "Bukti Pekerjaan",
              meta: {
                requiresAuth: true,
                role: "DFP",
              },
              component: UploadBuktiPengajuan,
            },
          ],
        },
        {
          path: "old-persetujuan",
          name: "Persetujuan",
          meta: {
            requiresAuth: true,
            role: "PSF",
          },
          component: Persetujuan,
        },
        {
          path: "persetujuan",
          name: "Persetujuan",
          meta: {
            requiresAuth: true,
            role: "PSF",
          },
          component: PersetujuanNew,
        },
        {
          path: "persetujuan/surat-tugas",
          name: "Buat Surat Tugas",
          meta: {
            requiresAuth: true,
            role: "PSF",
          },
          component: BuatSuratTugas,
        },
        {
          path: "arsip-fpp",
          name: "Arsip FPP",
          meta: {
            requiresAuth: true,
            role: "ARS",
          },
          component: ArsipPengajuanNew,
        },
        {
          path: "arsip-fpp-backup",
          name: "Arsip FPP",
          meta: {
            requiresAuth: true,
            role: "ARS",
          },
          component: ArsipPengajuan,
        },
        {
          path: "ubah-kata-sandi-pemeliharaan",
          name: "Ubah Kata Sandi Maintenance",
          meta: {
            requiresAuth: true,
            role: "DPM",
          },
          component: UbahKataSandiMaintenance,
        },
        {
          path: "daftar-fpp-pemeliharaan",
          name: "Daftar FPP Maintenance",
          meta: {
            requiresAuth: true,
            role: "DPM",
          },
          component: DaftarPengajuanMaintenance,
        },
        {
          path: "arsip-fpp-pemeliharaan",
          name: "Arsip FPP Maintenance",
          meta: {
            requiresAuth: true,
            role: "APM",
          },
          component: ArsipPengajuanMaintenance,
        },

        // MASTER GENERAL
        {
          path: "master-general",
          name: "Master General",
          disabled: true,
          redirect: "#",
          meta: { hideInBreadcrumb: true, requiresAuth: true },
          ...emptyPage,
          children: [
            {
              path: "akses-menu",
              name: "Akses Menu",
              meta: {
                requiresAuth: true,
                role: "MAK",
              },
              component: MasterAksesMenu,
            },
            {
              path: "divisi",
              name: "Divisi",
              meta: {
                requiresAuth: true,
                role: "DIV",
              },
              component: MasterDivisi,
            },
            {
              path: "pemeliharaan",
              name: "Pemeliharaan",
              meta: {
                requiresAuth: true,
                role: "PHN",
              },
              component: MasterPemeliharaanFPP,
            },
            {
              path: "manage-users",
              name: "Manage User",
              meta: {
                requiresAuth: true,
                role: "MSU",
              },
              component: ManageUser,
            },
          ],
        },

        // MASTER FPP
        {
          path: "master-fpp",
          name: "Master FPP",
          redirect: "/master-fpp/kategori-fpp",
          meta: { hideInBreadcrumb: true, requiresAuth: true, role: "MSF" },
          ...emptyPage,
          children: [
            {
              path: "kategori-fpp",
              name: "Kategori (FPP)",
              meta: {
                requiresAuth: true,
                role: "KAF",
              },
              component: MasterKategoriFPP,
            },
            {
              path: "sub-kategori-fpp",
              name: "Sub Kategori (FPP)",
              meta: {
                requiresAuth: true,
                role: "SKF",
              },
              component: MasterSubKategoriFPP,
            },
            {
              path: "kategori-jenis-fpp",
              name: "Kategori Jenis (FPP)",
              meta: {
                requiresAuth: true,
                role: "KJF",
              },
              component: MasterKategoriJenisFPP,
            },
            {
              path: "manajemen-akses-fpp",
              name: "Manajemen Akses FPP",
              meta: {
                requiresAuth: true,
                role: "KPF",
              },
              component: MasterManajemenAksesFPP,
            },
          ],
        },

        // MASTER CABANG
        {
          path: "master-cabang",
          name: "Master Cabang",

          redirect: "/master-cabang/cabang",
          meta: { hideInBreadcrumb: true, requiresAuth: true, role: "MSC" },
          ...emptyPage,
          children: [
            {
              path: "cabang",
              name: "Cabang",
              meta: {
                requiresAuth: true,
                role: "CAB",
              },
              component: MasterCabang,
            },
            {
              path: "cabang-edit",
              name: "Edit Cabang",
              meta: {
                requiresAuth: true,
                role: "CAB",
              },
              component: MasterCabangEdit,
            },

            {
              path: "manajemen-cabang",
              name: "Manajemen Cabang",
              meta: {
                requiresAuth: true,
                role: "MNC",
              },
              component: MasterManajemenCabang,
            },
          ],
        },

        // MASTER INVENTARIS
        {
          path: "master-inventaris",
          name: "Master Inventaris",
          redirect: "/master-inventaris/jenis-barang",
          meta: { hideInBreadcrumb: true, requiresAuth: true, role: "MAI" },
          ...emptyPage,
          children: [
            {
              path: "akses-inventaris",
              name: "Akses Inventaris",
              meta: {
                requiresAuth: true,
                role: "UAI",
              },
              component: AksesInventaris,
            },
            {
              path: "jenis-barang",
              name: "Jenis Barang",
              meta: {
                requiresAuth: true,
                role: "JEB",
              },
              component: MasterJenisBarang,
            },
            {
              path: "merk-barang",
              name: "Merk Barang",
              meta: {
                requiresAuth: true,
                role: "MEB",
              },
              component: MasterMerkBarang,
            },
            {
              path: "tipe-suplier",
              name: "Tipe Suplier",
              meta: {
                requiresAuth: true,
                role: "SUP",
              },
              component: MasterTipeSuplier,
            },
            {
              path: "suplier",
              name: "Suplier",
              meta: {
                requiresAuth: true,
                role: "SUP",
              },
              component: MasterSuplier,
            },
            {
              path: "merchant",
              name: "Merchant",
              meta: {
                requiresAuth: true,
                role: "SUP",
              },
              component: MasterMerchanOnline,
            },
            {
              path: "tipe-barang",
              name: "Tipe Barang",
              meta: {
                requiresAuth: true,
                role: "TIB",
              },
              component: MasterTipeBarang,
            },
          ],
        },

        // INVENTORY
        {
          path: "home-inventory",
          name: "Home Inventaris",
          meta: {
            requiresAuth: true,
            role: "HOM",
          },
          component: HomeInventory,
        },
        {
          path: "barang-masuk",
          name: "Barang Masuk",
          redirect: "/barang-masuk",
          meta: {
            requiresAuth: true,
            role: "BAM",
          },
          ...emptyPage,
          children: [
            {
              path: "",
              meta: {
                requiresAuth: true,
                role: "BAM",
              },
              component: BarangMasuk,
            },
            {
              path: ":id/detail",
              name: "Detail",
              meta: {
                requiresAuth: true,
                role: "BAM",
              },
              component: DetailBarangMasuk,
            },
          ],
        },
        {
          path: "terima-pembelian",
          name: "Terima Pembelian Barang",
          redirect: "/terima-pembelian",
          meta: {
            requiresAuth: true,
            role: "TPB",
          },
          ...emptyPage,
          children: [
            {
              path: "",
              meta: {
                requiresAuth: true,
                role: "TPB",
              },
              component: TerimaPembelian,
            },
            {
              path: ":id/detail",
              name: "Detail",
              meta: {
                requiresAuth: true,
                role: "TPB",
              },
              component: DetailTerimaPembelian,
            },
          ],
        },
        {
          path: "selisih-barang",
          name: "Selisih Barang",
          meta: {
            requiresAuth: true,
            role: "SBP",
          },
          ...emptyPage,
          children: [
            {
              path: "daftar-selisih",
              meta: {
                requiresAuth: true,
                role: "DSB",
              },
              component: SelisihBarang,
            },
            {
              path: "terima-selisih",
              meta: {
                requiresAuth: true,
                role: "TSB",
              },
              component: TerimaSelisihBarang,
            },
            {
              path: "arsip-selisih",
              meta: {
                requiresAuth: true,
                role: "ASB",
              },
              component: ArsipSelisihBarang,
            },
            {
              path: ":id/manage",
              meta: {
                requiresAuth: true,
                role: "TPB",
              },
              component: ManageSelisihBarang,
            },
          ],
        },
        {
          path: "barang-masuk/:id",
          name: "Detail",
          meta: {
            requiresAuth: true,
            role: "BAM",
          },
          component: DetailBarangMasuk,
        },

        {
          path: "stok-barang",
          name: "Stok Barang",
          redirect: "/stok-barang/aset-tetap",
          meta: {
            requiresAuth: true,
            role: "STB",
          },
          ...emptyPage,
          children: [
            {
              path: "aset-tetap",
              name: "Aset Tetap",
              meta: {
                requiresAuth: true,
                role: "AST",
              },
              ...emptyPage,
              component: AsetTetap,
            },
            {
              path: "aset-lancar",
              name: "Aset Lancar",
              meta: {
                requiresAuth: true,
                role: "ASL",
              },
              ...emptyPage,
              component: AsetLancar,
            },
          ],
        },
        {
          path: "input-data",
          name: "Input Data",
          redirect: "/input-data/pembelian",
          meta: {
            requiresAuth: true,
            role: "IND",
          },
          ...emptyPage,
          children: [
            {
              path: "pembelian",
              name: "Pembelian",
              redirect: "/input-data/pembelian",
              meta: {
                requiresAuth: true,
                role: "PEM",
              },
              ...emptyPage,
              children: [
                {
                  path: "",
                  meta: {
                    requiresAuth: true,
                    role: "PEM",
                  },
                  component: Pembelian,
                },
                {
                  path: "input",
                  name: "Input Pembelian",
                  meta: {
                    requiresAuth: true,
                    role: "PEM",
                  },
                  component: InputPembelian,
                },
                {
                  path: ":id/detail",
                  name: "Detail Pembelian",
                  meta: {
                    requiresAuth: true,
                    role: "PEM",
                  },
                  component: DetailPembelian,
                },
              ],
            },
            {
              path: "pengiriman",
              name: "Pengiriman",
              redirect: "/input-data/pengiriman",
              meta: {
                requiresAuth: true,
                role: "PEI",
              },
              ...emptyPage,
              children: [
                {
                  path: "",
                  meta: {
                    requiresAuth: true,
                    role: "PEI",
                  },
                  component: Pengiriman,
                },
                {
                  path: ":id/detail",
                  name: "Pengiriman Detail",
                  meta: {
                    requiresAuth: true,
                    role: "PEI",
                  },
                  component: DetailPengiriman,
                },
              ],
            },
            {
              path: "pemakaian",
              name: "Pemakaian",
              redirect: "/input-data/pemakaian",
              meta: {
                requiresAuth: true,
                role: "PEP",
              },
              ...emptyPage,
              children: [
                {
                  path: "",
                  meta: {
                    requiresAuth: true,
                    role: "PEP",
                  },
                  component: Pemakaian,
                },
              ],
            },
            {
              path: "inventaris-perorangan",
              name: "Inventaris Perorangan",
              redirect: "/input-data/inventaris-perorangan",
              meta: {
                requiresAuth: true,
                role: "INP",
              },
              ...emptyPage,
              children: [
                {
                  path: "",
                  meta: {
                    requiresAuth: true,
                    role: "INP",
                  },
                  component: InventarisPerorangan,
                },
              ],
            },
          ],
        },

        {
          path: "approve-void",
          name: "Approve Void",
          meta: {
            requiresAuth: true,
            role: "APV",
          },
          component: ApprovalVoid,
        },

        {
          path: "approve-void-pembelian",
          name: "Approve Void pembelian",
          redirect: "/approve-void-pembelian",
          meta: {
            requiresAuth: true,
            role: "AVP",
          },
          ...emptyPage,
          children: [
            {
              path: "",
              meta: {
                requiresAuth: true,
                role: "AVP",
              },
              component: ApprovalVoidPembelian,
            },
            {
              path: ":id/detail",
              name: "Approve Void pembelian Detail",
              meta: {
                requiresAuth: true,
                role: "AVP",
              },
              component: DetailApprovalVoidPembelian,
            },
          ],
        },

        //Inventaris Accounting
        {
          path: "pembelian-accounting",
          name: "Pembelian Accounting",
          ...emptyPage,
          redirect: "pembelian-accounting",
          children: [
            {
              path: "",
              meta: {
                requiresAuth: true,
                role: "PEA",
              },
              component: PembelianAccounting,
            },
            {
              path: ":id/detail",
              name: "Detail Pembelian",
              meta: {
                requiresAuth: true,
                role: "PEA",
              },
              component: DetailPembelianAccounting,
            },
          ],
        },

        //Laporan
        {
          path: "laporan",
          name: "Laporan",
          ...emptyPage,
          redirect: "laporan/stok-barang",
          children: [
            {
              path: "stok-barang",
              name: "Stok Barang",
              meta: {
                requiresAuth: true,
                role: "LSB",
              },
              ...emptyPage,
              redirect: "stok-barang",
              children: [
                {
                  path: "",
                  meta: {
                    requiresAuth: true,
                    role: "LSB",
                  },
                  component: LaporanStokBarang,
                },
                {
                  path: ":id/detail",
                  name: "History Barang",
                  meta: {
                    requiresAuth: true,
                    role: "LSB",
                  },
                  component: HistoryBarang,
                },
              ],
            },
            {
              path: "inventaris",
              name: "Laporan Inventaris",
              meta: {
                requiresAuth: true,
                role: "LIN",
              },
              ...emptyPage,
              redirect: "inventaris",
              children: [
                {
                  path: "",
                  meta: {
                    requiresAuth: true,
                    role: "LIN",
                  },
                  component: LaporanInventaris,
                },
                {
                  path: ":id/detail",
                  name: "History Barang",
                  meta: {
                    requiresAuth: true,
                    role: "LSB",
                  },
                  component: HistoryBarang,
                },
              ],
            },
            {
              path: "pemakaian",
              name: "Laporan Pemakaian",
              meta: {
                requiresAuth: true,
                role: "LPE",
              },
              ...emptyPage,
              redirect: "pemakaian",
              children: [
                {
                  path: "",
                  meta: {
                    requiresAuth: true,
                    role: "LPE",
                  },
                  component: LaporanPemakaian,
                },
              ],
            },
            {
              path: "pengiriman",
              name: "Laporan Pengiriman",
              meta: {
                requiresAuth: true,
                role: "LPN",
              },
              ...emptyPage,
              redirect: "pengiriman",
              children: [
                {
                  path: "",
                  meta: {
                    requiresAuth: true,
                    role: "LPN",
                  },
                  component: LaporanPengiriman,
                },
              ],
            },
            {
              path: "pembelian",
              name: "Laporan Invoice",
              meta: {
                requiresAuth: true,
                role: "LPN",
              },
              ...emptyPage,
              redirect: "pembelian",
              children: [
                {
                  path: "",
                  meta: {
                    requiresAuth: true,
                    role: "LPB",
                  },
                  component: LaporanPembelian,
                },
              ],
            },
          ],
        },
        // REFUND
        {
          path: "refund-pending",
          name: "Refund",
          meta: {
            requiresAuth: true,
            role: "RFP",
          },
          component: RefundPending,
        },
        {
          path: "refund-history",
          name: "History",
          meta: {
            requiresAuth: true,
            role: "RFH",
          },
          component: RefundPembelianHistory,
        }
      ],
    },
    {
      path: "/page-not-found",
      name: "error",
      component: NotFound,
    },
    {
      path: "/login",
      name: "Login",
      meta: {
        requiresVisitor: true,
      },
      component: Login,
    },
    {
      path: "/testing",
      name: "Testing",
      component: Testing,
    },
  ];
}

let router = new Router({
  mode: "history",
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

// INFINITY LOOP
// router.afterEach((to) => {
//   localStorage.setItem("ls_route_key", to.name);
// });

router.beforeEach((to, from, next) => {
  const child = JSON.parse(JSON.stringify(localStorage.getItem("m_child")));

  if (child) {
    const arrChild = child.split(",");
    const findChild = arrChild.find((item) => item === to.meta.role);
    if (findChild) {
      next();
    } else {
      const lastRouteName = localStorage.getItem("ls_route_key");

      if (store.getters.loggedIn) {
        next({
          name: lastRouteName,
        });
      } else {
        next();
      }
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        name: "Login",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    const lastRouteName = localStorage.getItem("ls_route_key");

    if (store.getters.loggedIn) {
      next({
        name: lastRouteName,
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
