import axiosClient from "../../config/api";

const state = {
  master_suppliers: [],
  master_merchants: []
};

const getters = {
  master_suppliers: (state) => state.master_suppliers,
  master_merchants: (state) => state.master_merchants,
};


const mutations = {
  setMasterSupplier(state, data) {
    state.master_suppliers = data;
  },
  setMasterMerchant(state, data) {
    state.master_merchants = data;
  },
};
const actions = {
  async getMasterSupplier({ commit }, { type_id }) {
    const response = await axiosClient({
      url: "supplier/all",
      params: { type_id }
    });
    commit("setMasterSupplier", response.data.data);
    return response.data.data
  },
  async getMasterMerchant({ commit }, params) {
    const response = await axiosClient({
      url: "supplier-merchant/all",
      method: 'GET',
      params
    });
    commit("setMasterMerchant", response.data.data);
    return response.data.data
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
