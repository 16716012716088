import moment from "moment";

export default {
  changeDateFormat(date) {
    if (this.isNullOrEmpty(date) || date == "null") {
      return "-";
    }

    if (date) {
      const month_names = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      let year = date.slice(0, 4);
      let month = date.slice(5, 7);
      let day = date.slice(8, 10);
      let dayInt = parseInt(day, 10);
      let monthInt = parseInt(month, 10);

      return `${dayInt} ${month_names[monthInt - 1]} ${year}`;
    }

    return "-";
  },

  changeDateFormatGetHour(date) {
    if (date) {
      let clock = date.slice(11, 16);
      return `${clock}`;
    }
    return "-";
  },

  addThousandSeparator(number) {
    // const rounded =  Math.round(number * 100) / 100;
    if (number == 0) {
      return 0;
    }

    if (number) {
      return `${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
    }

    return "-";
  },

  removeThousandSeparator(num) {
    if (num) {
      let removeDotNum = ("" + num).replace(/\./g, "");
      if (isNaN(removeDotNum)) {
        return "";
      } else {
        return Number(removeDotNum);
      }
    } else if (num == 0) return 0;

    return "";
  },

  changeToValue(num) {
    if (num) {
      let removeDotNum = num.replace(/\./g, "");
      return removeDotNum;
    }
    return "";
  },

  makeQuery(page, size) {
    let string = "?page=" + page + "&size=" + size;
    return string;
  },

  getAllCabang() {
    let getCabang = localStorage.getItem("cabang");
    let allCabang = JSON.parse(getCabang);
    return allCabang;
  },

  getCabangId() {
    return localStorage.getItem("activeCabangId");
  },

  setFlash(msg) {
    localStorage.setItem("flash", msg);
  },

  getFlash() {
    let flash = localStorage.getItem("flash");
    localStorage.removeItem("flash");
    return flash;
  },

  returnYears() {
    let currentYear = new Date().getFullYear();
    let arrYear = [];
    let x = 2000;
    while (x <= currentYear + 2) {
      arrYear.push(x);
      x++;
    }
    return arrYear;
  },

  createEmptyFile() {
    var f = new File([""], "blank.pgifile");
    return f;
  },

  toastSucc(vue, msg, toastTitle = "Sukses") {
    vue.$bvToast.toast(msg, {
      title: toastTitle,
      autoHideDelay: 5000,
      appendToast: true,
      variant: "success",
    });
  },

  toastErr(vue, msg, toastTitle = "Gagal") {
    vue.$bvToast.toast(msg, {
      title: toastTitle,
      autoHideDelay: 5000,
      appendToast: true,
      variant: "danger",
    });
  },

  parseError(vue, error, toastTitle = "Gagal") {
    console.log(error);

    let message = "";

    if (error.response && error.response.data.reason != null) {
      message = error.response.data.reason;
    } else if (error.message.startsWith("timeout")) {
      message =
        "Gagal menghubungi server, periksa jaringan anda dan coba kembali! [ERR-TIMEOUT]";
    } else {
      message = error.message;
    }

    this.toastErr(vue, message, toastTitle);
  },

  toastAlert(vue, msg, toastTitle = "Alert") {
    vue.$bvToast.toast(msg, {
      title: toastTitle,
      autoHideDelay: 5000,
      appendToast: true,
      variant: "primary",
    });
  },

  isNotNullAndNotEmpty(input) {
    return input != null && input != "" && input.length != 0;
  },

  isNullOrEmpty(input) {
    return input == null || input == "";
  },

  printPayload(payload) {
    for (var pair of payload.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }
  },

  resizeImage(base64Str, maxWidth = 1920, maxHeight = 1080) {
    return new Promise((resolve) => {
      let img = new Image();
      img.src = base64Str;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        const MAX_WIDTH = maxWidth;
        const MAX_HEIGHT = maxHeight;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        let newBase64Str = canvas.toDataURL("image/jpeg", 0.9);
        if (newBase64Str.length < base64Str.length) {
          resolve(newBase64Str);
        } else {
          resolve(base64Str);
        }
      };
    });
  },

  validasiUmurNasabah(umur) {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yearNow = today.getFullYear();
    var split = umur.split("-");
    let tahun = split[0];
    let bulan = split[1];
    let hari = split[2];
    var calculate = yearNow - 18;

    if (calculate > tahun) {
      return true;
    } else if (calculate == tahun) {
      if (bulan < mm) {
        return true;
      } else if (bulan == mm) {
        if (hari <= dd) {
          return true;
          // return this.$helper.toastErr(this, "Maaf umur nasabah belum 18 tahun, tidak bisa melakukan gadai.")
        } else {
          return false;
          // return this.$helper.toastErr(this, "Maaf umur nasabah belum 18 tahun, tidak bisa melakukan gadai.")
        }
      } else {
        return false;
        // return this.$helper.toastErr(this, "Maaf umur nasabah belum 18 tahun, tidak bisa melakukan gadai.")
      }
    } else {
      return false;
      // return this.$helper.toastErr(this, "Maaf umur nasabah belum 18 tahun, tidak bisa melakukan gadai.")
    }
  },

  // convert int to rupiah
  rupiah(number) {
    const intData = Math.round(number);
    return new Intl.NumberFormat("id-ID", {
      currency: "IDR",
    }).format(intData);
  },
  tanggal(date) {
    return moment(date).format("DD-MM-YYYY")
  },

  // Convert Date to SQL with Moment
  convertDateToSQL(date) {
    if (date == null || date == "") {
      return "";
    }

    return moment(date).format("YYYY-MM-DD");
  },

  // Convert Date to Date Picker with Moment
  convertDateToDatePicker(date) {
    if (date == null || date == "") {
      return "";
    }

    return moment(date).format("DD MMMM YYYY");
  },

  getAllMonthName() {
    let month = [];

    for (let i = 0; i < 12; i++) {
      month.push(moment().month(i).format("MMMM"));
    }

    const withKey = month.map((month, index) => {
      return {
        key: index < 9 ? "0" + (parseInt(index) + 1) : parseInt(index) + 1,
        value: month,
      };
    });

    return withKey;
  },
};
