import axiosClient from "../../config/api";

const state = {
    isLoading: false,
    users: [],
    resultUsers: [],
};

const getters = {
    userLists: (state) => state.users,
    resultUsers: (state) => state.resultUsers,
};

const mutations = {
    LOADING: (state, loadingStatus) => {
        state.isLoading = loadingStatus
    },
    GET_USER: (state, response) => {
        state.users = response;
    },
    CREATE_USER: (state, response) => {
        state.users = response;
    },
    SEARCH_USER: (state, response) => {
        state.resultUsers = response;
    },
};

const actions = {
    async getUsers(context, params) {
        try {
            context.commit('LOADING', true)
            const response = await axiosClient({
                method: 'GET',
                url: '/admin/management-users',
                params: params
            })
            if (response.status === 200) {
                const users = response.data.data
                const dataUsers = users.data.map(item => {
                    return {
                        id: item.id,
                        name: item.name || "",
                        username: item.username || "",
                        email: item.email || "",
                        devisi_id: item.devisi_id || "",
                        role: item.role ? item.role.name : "",
                        is_active: item.is_active === 1 ? item.is_active : 0,
                    }
                })
                context.commit("GET_USER", {
                    users: dataUsers,
                    pagination: {
                        currentPage: users.current_page,
                        rows: users.total,
                        per_page: users.per_page,
                    }
                });
                return {
                    users: dataUsers,
                    pagination: {
                        currentPage: users.current_page,
                        rows: users.total,
                        per_page: users.per_page,
                    }
                }
            } else {
                return false;
            }
        } catch (error) {
            console.error(error)
        } finally {
            context.commit('LOADING', false)
        }
    },
    async createUser(context, data) {
        try {
            context.commit('LOADING', true)
            const response = await axiosClient({
                method: 'POST',
                url: '/admin/management-users/create',
                data: data
            })
            if (response.status === 200) {
                context.commit("CREATE_USER", response.data);
                return response.data;
            } else {
                return false;
            }
        } catch (error) {
            console.error(error)
        } finally {
            context.commit('LOADING', false)
        }
    },
    async getDetailUser(context, id) {
        try {
            context.commit('LOADING', true)
            const response = await axiosClient({
                method: 'GET',
                url: '/admin/management-users/' + id
            })
            if (response.status === 200) {
                return response.data.data;
            } else {
                return false;
            }
        } catch (error) {
            console.error(error)
        } finally {
            context.commit('LOADING', false)
        }
    },
    async updateUser(context, request) {
        try {
            context.commit('LOADING', true)
            const response = await axiosClient({
                method: 'PUT',
                url: '/admin/management-users/update/' + request.id,
                data: request.data
            })
            if (response.status === 200) {
                context.commit("GET_USER", response.data);
                return response.data;
            } else {
                return false;
            }
        } catch (error) {
            console.error(error)
        } finally {
            context.commit('LOADING', false)
        }
    },
    async searchUser(context, params) {
        try {
            context.commit('LOADING', true)
            const response = await axiosClient({
                method: 'GET',
                url: 'admin/management-users/all',
                params: params
            })
            if (response.status === 200) {
                const resultUsers = response.data.data
                context.commit("SEARCH_USER", resultUsers);
                return resultUsers
            } else {
                return false;
            }
        } catch (error) {
            console.error(error)
        } finally {
            context.commit('LOADING', false)
        }
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
