import axiosClient from "../../config/api";

const state = {
    filtered_cities: [],
    cities: []
};

const getters = {
    filtered_cities: (state) => state.filtered_cities,
    cities: (state) => state.cities,
};


const mutations = {
    setCityFiltered(state, data) {
        state.filtered_cities = data;
    },
    setCity(state, data) {
        state.cities = data;
    }
};
const actions = {
    async getCityFiltered({ commit }) {
        const response = await axiosClient({
            url: "admin/wilayah/kabupaten-by-user",
            params: {
                order_by: "DESC"
            }
        });

        const datas = response.data.data.map(item => {
            return {
                id: item.id,
                text: item.name
            }
        })
        commit("setCityFiltered", datas);
        return datas
    },
    async getCity({ commit }) {
        const response = await axiosClient({
            url: "admin/wilayah/kabupaten-by-user",
            params: {
                order_by: "DESC"
            }
        });

        const datas = response.data.data.map(item => {
            return {
                id: item.id,
                text: item.name
            }
        })
        commit("setCity", datas);
        return datas
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
