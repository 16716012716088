import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import dashboard from "./modules/dashboard";
import global_data from "./modules/global_data";
import loading from "./modules/loading";

// Modules
import inventaris from "./modules/inventaris";
import pengiriman from "./modules/pengiriman";
import pembelian from "./modules/pembelian";
import barang from "./modules/barang";
import pemakaian from "./modules/pemakaian";
import daftarfpp from "./modules/daftarfpp";
import refund from "./modules/refund";
import fpp from "./modules/fpp";
import persetujuan from "./modules/persetujuan";
import global_query from "./modules/global_query";
import pengajuan from "./modules/pengajuan";

import users from "./modules/users";
import supplier from "./modules/supplier";
import wilayah from "./modules/wilayah";

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    session: {
      token: localStorage.getItem("token") || null,
    },
    isLoggingIn: false,
    isLoggingOut: false,
    prevRoute: null,
    messageAuth: false,
    role: localStorage.getItem("role") || null,
  },
  getters: {
    loggedIn: (state) => state.session.token !== null,
    token: (state) => state.session.token,
    beforeRouteEnter: (to, from, next, state) => {
      return state.prevRoute == from;
    },
  },
  mutations: {
    retrieveAuthentication: (state, response) => {
      state.session.token = response.token;
      state.session.role = response.role;
    },
    destroyAuthentication: (state) => {
      state.session.token = null;
    },
  },
  actions: {
    destroyAuthentication(context) {
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          context.state.isLoggingOut = true;
          axios
            .post(`api/auth/logout`)
            .then((response) => {
              if (response.status == 200) {
                localStorage.clear();

                context.commit("destroyAuthentication");
                resolve(response);
              }
            })
            .catch((error) => {
              localStorage.clear();
              context.commit("destroyAuthentication");
              reject(error);
            })
            .finally(() => {
              context.state.isLoggingOut = false;
            });
        });
      }
    },

    retrieveAuthentication(context, credentials) {
      return new Promise((resolve, reject) => {
        context.state.isLoggingIn = true;

        axios
          .post(`api/auth/login`, {
            username: credentials.username,
            password: credentials.password,
          })
          .then((response) => {
            if (response.status == 200) {
              const token = response.data.token;
              const roleMenu = response.data.user.role.role_menu;
              const kategoriProses = response.data.kategori_proses;

              let menuChild = [];
              let katProses = [];
              // let activeCabang = "Tanpa Cabang";
              // let activeCabangId = 0;
              context.state.messageAuth = false;

              for (let menu of roleMenu) {
                if (menu.menu != null) {
                  menuChild.push(menu.menu.code);
                }
              }

              if (kategoriProses.length > 0) {
                for (let proses of kategoriProses) {
                  katProses.push({
                    user: proses.user_id,
                    divisi: proses.devisi_id,
                    jenis: proses.id_kategori_jenis_fpp,
                    proses: proses.kategori_proses,
                  });
                }
              }

              localStorage.setItem("id", response.data.user.id);
              localStorage.setItem("name", response.data.user.name);
              localStorage.setItem("role", response.data.user.role.name);
              localStorage.setItem("role_id", response.data.user.role_id);
              localStorage.setItem("is_authorized", "true");
              localStorage.setItem("m_top", response.data.top_menu);
              localStorage.setItem("m_child", menuChild);
              localStorage.setItem("k_proses", JSON.stringify(katProses));
              localStorage.setItem("token", token);
              localStorage.setItem("fcmToken", token);
              localStorage.setItem(
                "cabang",
                JSON.stringify(response.data.cabang)
              );
              localStorage.setItem("is_new", response.data.user.is_new_user);

              axios.defaults.headers.common["Authorization"] =
                "Bearer " + token;

              window.location.href = "/dashboard";
              // window.location.href = "/";

              // Setup activeCabang for display
              // if (response.data.cabang.length == 1) {
              //   activeCabang = response.data.cabang[0].name;
              //   activeCabangId = response.data.cabang[0].id;
              // } else if (response.data.cabang.length > 1) {
              //   activeCabang = "Multi Cabang";
              //   activeCabangId = response.data.cabang[0].id;
              // }

              // localStorage.setItem("activeCabang", activeCabang);
              // localStorage.setItem("activeCabangId", activeCabangId);

              // to do flex baru cabang pusat

              // if (localStorage.getItem("version") === null) {
              //   localStorage.setItem("reload", 1);
              //   localStorage.setItem("version", "1.0.0");
              // } else {
              //   if (localStorage.getItem("version") !== response.data.version) {
              //     localStorage.setItem("reload", 1);
              //   }

              //   localStorage.setItem("version", response.data.version);
              // }

              context.commit("retrieveAuthentication", {
                token,
                role: response.data.user.role.name,
              });
              resolve(response);
            }
          })
          .catch((error) => {
            console.log(error);
            context.state.messageAuth = true;
            reject(error);
          })
          .finally(() => {
            context.state.isLoggingIn = false;
          });
      });
    },
  },
  modules: {
    loading,
    dashboard,
    global_data,
    inventaris,
    pengiriman,
    pembelian,
    barang,
    pemakaian,
    users,
    daftarfpp,
    refund,
    supplier,
    wilayah,
    fpp,
    persetujuan,
    global_query,
    pengajuan
  },
});

export default store;
