import axiosClient from "../../config/api";

const state = {
    daftar_pengajuan: null,
    detail_pengajuan: null,
    selected_id: null
};

const getters = {
    daftar_pengajuan: (state) => state.daftar_pengajuan,
    selected_id: (state) => state.selected_id,
    detail_pengajuan: (state) => state.detail_pengajuan,
};

const mutations = {
    setDaftarPengajuan(state, data) {
        state.daftar_pengajuan = data;
    },
    setDetailPengajuan(state, data) {
        state.detail_pengajuan = data;
    },
    setSelectedId(state, data) {
        state.selected_id = data;
    },
};
const actions = {
    async getDaftarPengajuan({ commit }, params) {
        const response = await axiosClient({
            url: 'internal-memo/memo/index',
            params: {
                per_page: 15,
                ...params,
            }
        });
        const datas = response.data.data
        commit("setDaftarPengajuan", datas);
        return datas
    },
    async getDetailPengajuan({ commit }, id) {
        const response = await axiosClient({
            url: 'internal-memo/memo/' + id
        });
        const datas = response.data.data
        commit("setDetailPengajuan", datas);
        return datas
    },
    async createSelectedId({ commit }, id) {
        commit("setSelectedId", id);
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
