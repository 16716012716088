import axios from 'axios'
import store from "../store";

const MODE = process.env.VUE_APP_MODE;
const API = process.env.VUE_APP_API;
const API_DEV = process.env.VUE_APP_API_DEV;
const baseURL = MODE === 'production' ? API : API_DEV;
const axiosClient = axios.create({
    baseURL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem("token")
    }
});

axiosClient.interceptors.request.use((config) => {
    store.commit("loading/setLoading", true);
    return config;
});

axiosClient.interceptors.response.use(
    (res) => {
        store.commit("loading/setLoading", false);
        return Promise.resolve(res);
    },
    (err) => {
        store.commit("loading/setLoading", false);
        return Promise.reject(err);
    }
);

export default axiosClient